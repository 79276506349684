<template>
    <v-container fluid class="FAQ-wrapper">
        <div class="FAQ-logo">
            <router-link to="/">
                <v-img
                    width="344"
                    src="@/assets/images/logo/noodzly-logo.png"
                />
            </router-link>
        </div>
        <div class="FAQ-title">
            <h1 style="color: #ffffff">FAQ</h1>
        </div>

        <div
            class="FAQ-section--groups"
            v-for="(section, name, index) in faq_sections"
            :key="index"
        >
            <div class="FAQ-section--title">
                {{ name }}
            </div>
            <collapsible-section v-for="(item, key) in section" :key="key">
                <template v-slot:title>
                    {{ item.section_title }}
                </template>
                <div v-html="item.section_content"></div>
            </collapsible-section>
        </div>
    </v-container>
</template>

<script>
import CollapsibleSection from "@/components/app/common/CollapsibleSection";
import FaqSections from "../../models/FaqSections";

export default {
    name: "FAQ",
    data: function () {
        return {
            faq_sections: {},
        };
    },
    components: {
        CollapsibleSection,
    },
    mounted() {
        FaqSections.get().then((res) => {
            this.faq_sections = res.pop();
        });
    },
};
</script>

<style scoped lang="scss">
.FAQ {
    &-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column;
        max-width: 700px;
        padding-bottom: 87px;
    }

    &-logo {
        width: 146px;
        //width: 344px;

        //@include media("md-and-down") {
        //    width: 146px;
        //}
    }

    &-title {
        h1 {
            font-size: 29px;
            //font-size: 85px;
            line-height: 24px;
            //line-height: 103px;
            margin-top: 20px;
            //margin-top: 58px;
            margin-bottom: 2px;
            font-family: "Proxima Nova Lt";

            //@include media("md-and-down") {
            //    margin-top: 20px;
            //    font-size: 29px;
            //    line-height: 24px;
            //}
        }
    }

    &-section {
        &--groups {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            width: 100%;
        }

        &--title {
            font-size: 20px;
            line-height: 37px;
            margin-top: 20px;
            margin-bottom: 3px;
            font-family: "Proxima Nova Lt";
            text-transform: uppercase;

            //@include media("md-and-down") {
            //    margin-top: 41px;
            //    font-size: 13px;
            //    line-height: 13px;
            //}
        }
    }
}
</style>
