<template>
    <div
        class="CollapsibleSection-wrapper"
        @click="handleCollapse"
        :class="{
            close: toggle,
        }"
    >
        <div class="CollapsibleSection-header">
            <slot name="title"></slot>
            <span class="CollapsibleSection-header--chevron">
                <v-icon
                    >mdi-chevron-{{ toggle === true ? "down" : "up" }}</v-icon
                >
            </span>
        </div>
        <div class="CollapsibleSection-content" @click.stop>
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    name: "CollapsibleSection",
    data() {
        return {
            toggle: true,
        };
    },
    props: {
        collapse: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        handleCollapse() {
            this.toggle = !this.toggle;
        },
    },
};
</script>
<style scoped lang="scss">
.CollapsibleSection {
    &-wrapper {
        margin-top: 25px;
        background-color: #262c34;
        border-radius: 10px;
        color: #ffffff;
        //padding: 36px 49px 30px 49px;
        padding: 26px 39px 20px 39px;
        width: 100%;
        cursor: pointer;

        &.close {
            background-color: #191c21;

            .CollapsibleSection-header {
                padding-bottom: 0;
                border-bottom: none;
            }

            .CollapsibleSection-content {
                height: 0px;
                padding-top: 0;
            }
        }

        @include media("md-and-down") {
            width: 100%;
            margin-top: 13px;
            padding: 14px 19px 14px 19px;
        }
    }

    &-header {
        font-size: 20px;
        line-height: 30px;
        font-family: "Proxima Nova Lt";
        //padding-left: 38px;
        padding-bottom: 36px;
        border-bottom: 1px solid #707070;
        justify-content: space-between;
        display: flex;

        &--chevron .v-icon {
            color: #fff !important;
            width: 23px !important;
            font-size: 36px !important;
            height: 13px;

            @include media("md-and-down") {
                width: 10px !important;
                height: 5px !important;
                font-size: 21px !important;
            }
        }

        @include media("md-and-down") {
            padding-left: 0;
            font-size: 11px;
            line-height: 20px;
            padding-bottom: 14px;
        }
    }

    &-content {
        padding-top: 36px;
        line-height: 45px;
        font-size: 20px;
        //padding-left: 38px;
        max-width: 1177px;
        transition: all 0.4s ease;
        overflow: hidden;
        color: #c1c1c1;

        @include media("md-and-down") {
            font-size: 12px;
            line-height: 18px;
            padding-left: 0px;
            padding-top: 14px;
        }
    }
}
</style>
